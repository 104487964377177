import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: '3gGlow Fashion - Home',
      metaTags: [
        {
          name: 'description',
          content: '3gGlow Fashion - Glow is the goal.'
        },
        {
          name: 'keywords',
          content: 'tailor,fashion,clothings,wears'
        }
      ]
    }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue'),
    meta: {
      title: '3gGlow Fashion - About Us'
    }
  },
  {
    path: '/services',
    name: 'services',
    component: () => import('../views/ServicesView.vue'),
    meta: {
      title: '3gGlow Fashion - Our Services'
    }
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/ContactView.vue'),
    meta: {
      title: '3gGlow Fashion - Contact Us'
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { top: 0 }
  }
});

const DEFAULT_TITLE = '3gGlow Fashion';
router.beforeEach((to) => {
  document.title = to.meta.title || DEFAULT_TITLE;
});

export default router
