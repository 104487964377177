<template>
	<div class="modal" id="spinIt" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
	  <div class="modal-dialog" role="document">
	    <div class="modal-content">
	    	<div class="modal-body">
	    		<div>
					<div class="lds-dual-ring"></div>
					<h3>Please hold on...</h3>
					<p>
						<br><br><br>
		                <button type="button" class="button button-contactForm boxed-btn" @click="$store.dispatch('stopSpinner')">
		                  Close Spinner
		                </button>
					</p>
	    		</div>
			</div>
	    </div>
	  </div>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex'

	export default {
		computed: {
		    getSpinnerState () {
		      	return this.$store.getters.getSpinnerState;
		    }
	  	},
	  	watch: {
		    getSpinnerState (newVal) {
		    	if(newVal === true){
		    		$('#spinIt').modal('show');
		    	}else{
		    		$('#spinIt').modal('hide');
		    	}
		    }
	  	}
	}
</script>
<style scoped>
	.modal-content {
	    background: none;
    	border: none;
	}
	.modal-body {
    	display: flex;
    	align-items: center;
    	align-content: center;
    	justify-content: center;
		text-align: center;
		height: 100vh;
	}
	h3{
		color: white;
	}
	.lds-dual-ring {
	  display: inline-block;
	  width: 80px;
	  height: 80px;
	}
	.lds-dual-ring:after {
	  content: " ";
	  display: block;
	  width: 64px;
	  height: 64px;
	  margin: 8px;
	  border-radius: 50%;
	  border: 6px solid #fff;
	  border-color: #fff transparent #fff transparent;
	  animation: lds-dual-ring 1.2s linear infinite;
	}
	@keyframes lds-dual-ring {
	  0% {
	    transform: rotate(0deg);
	  }
	  100% {
	    transform: rotate(360deg);
	  }
	}
</style>