import { createStore } from 'vuex'

export default createStore({
  state: {
    spinnerStart: false
  },
  mutations: {
    startSpinner(state){
      state.spinnerStart = true
    },
    stopSpinner(state){
      state.spinnerStart = false
    }
  },
  actions: {
    startSpinner({ commit }){
      commit('startSpinner')
    },
    stopSpinner({ commit }){
      commit('stopSpinner')
    }
  },
  getters: {
    getSpinnerState(state){
      return state.spinnerStart
    }
  },
  modules: {
  }
})
