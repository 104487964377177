<template>
  <router-view/>

  <Spinner />
</template>

<script>
  import Spinner from './components/Spinner.vue'

  export default {
    components: {
      Spinner
    }
  }
</script>

