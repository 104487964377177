<template>
  <HeaderComponent/>
  
  <main>
    <div class="slider-area  position-relative ">
      <div class="slider-active">
        <div class="single-slider position-relative hero-overly slider-height  d-flex align-items-center" style="background-image:url(assets/img/hero/h1_hero.png);" data-background="assets/img/hero/h1_hero.png">
          <div class="container">
            <div class="row">
              <div class="col-xl-6 col-lg-6">
                <div class="hero-caption">
                  <img class="rotateme" src="assets/img/hero/hero-icon.png" alt="" data-animation="zoomIn" data-delay="1s">
                  <h1 data-animation="fadeInLeft" data-delay=".4s">We make wears that keep you glowing</h1>
                  <p data-animation="fadeInLeft" data-delay=".6s">TLorem ipsum dolor sit amet, consectetur adipiscing elit. 
                      Suspendisse varius enim in eros elementum tristique. Duis 
                  cursus, mi quis viverra ornare.</p>
                  <router-link to="/services" class="btn"  data-animation="fadeInLeft" data-delay=".8s" >Our Services</router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="hero-img">
            <img src="assets/img/hero/h1_hero1.png" alt=""  data-animation="fadeInRight" data-transition-duration="5s">
          </div>
        </div>
      </div>
    </div>
    <div class="clients-area section-padding40">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-md-6 col-sm-6">
            <div class="single-offers mb-50 wow fadeInUp" data-wow-delay=".2s" >
              <div class="offers-img">
                <img src="assets/img/gallery/offers1.png" alt="">
              </div>
              <div class="offers-cap">
                <span>1</span>
                <h3><a href="services.html">Tailor Sweing</a></h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.</p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-6">
            <div class="single-offers mb-50 wow fadeInUp" data-wow-delay=".4s">
              <div class="offers-img">
                <img src="assets/img/gallery/offers2.png" alt="">
              </div>
              <div class="offers-cap">
                <span>2</span>
                <h3><a href="services.html">Mesurement</a></h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.</p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-6">
            <div class="single-offers mb-50 wow fadeInUp" data-wow-delay=".6s">
              <div class="offers-img">
                <img src="assets/img/gallery/offers3.png" alt="">
              </div>
              <div class="offers-cap">
                <span>3</span>
                <h3><a href="services.html">Ready-made</a></h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <section class="visit-tailor-area fix">
      <div class="tailor-offers"></div>
      <div class="tailor-details">
        <h2>About our<br>tailor house</h2>
        <p class="pera-top">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros.</p>
        <p class="pera-bottom">Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
            Suspendisse varius enim in eros elementum tristique. Duis 
        cursus, mi quis viverra ornare.</p>
        <router-link to="/about" class="btn">More About Us</router-link>
      </div>
    </section>

    <section class="categories-area section-padding40">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-xl-6 col-lg-6 col-md-7 col-sm-9">
            <div class="section-tittle text-center mb-60">
              <h2>Why use our service?</h2>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros.</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3 col-md-6 col-sm-6">
            <div class="single-cat mb-50 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".2s">
              <div class="cat-icon">
                <img src="assets/img/icon/services1.svg" alt="">
              </div>
              <div class="cat-cap">
                <h5>Tailor Sweing</h5>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.</p>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6">
            <div class="single-cat mb-50 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".2s">
              <div class="cat-icon">
                <img src="assets/img/icon/services2.svg" alt="">
              </div>
              <div class="cat-cap">
                <h5>Tailor Sweing</h5>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.</p>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6">
            <div class="single-cat mb-50 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".4s">
              <div class="cat-icon">
                <img src="assets/img/icon/services3.svg" alt="">
              </div>
              <div class="cat-cap">
                <h5>Tailor Sweing</h5>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.</p>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6">
            <div class="single-cat mb-50 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".5s">
              <div class="cat-icon">
                <img src="assets/img/icon/services4.svg" alt="">
              </div>
              <div class="cat-cap">
                <h5>Tailor Sweing</h5>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="video-area section-bg2 d-flex align-items-center" style="background-image:url(assets/img/gallery/video-bg.png);" data-background="assets/img/gallery/video-bg.png">
      <div class="container">
        <div class="video-wrap position-relative">
          <div class="video-icon" >
            <a class="popup-video btn-icon" href="https://www.youtube.com/watch?v=up68UAfH0d0"><i class="fas fa-play"></i></a>
          </div>
        </div>
      </div>
    </div>
  </main>
  
  <FooterComponent/>
</template>

<script>
  import HeaderComponent from '@/components/head/Header.vue';
  import FooterComponent from '@/components/foot/Footer.vue';

  export default {
    name: 'HomeView',
    title: '3gGlow Fashion - Home',
    components: {
      HeaderComponent,
      FooterComponent,
    }
  }
</script>
