<template>
  <header>
    <div class="header-area header_area">
      <div class="main-header">
        <div class="header-bottom header-sticky">
          <div class="logo">
            <router-link to="/"><img src="assets/img/logo/logo.png" style="max-height:80px;"></router-link>
          </div>
          <div class="header-left  d-flex f-right align-items-center">
            <div class="main-menu f-right d-none d-lg-block">
              <nav> 
                <ul id="navigation">
                  <li><router-link to="/">Home</router-link></li>
                  <li><router-link to="/about">About</router-link></li>
                  <li><router-link to="/services">Services</router-link></li>
                  <li><router-link to="/contact">Contact</router-link></li>
                </ul>
              </nav>
            </div>
            <div class="header-right-btn f-right d-none d-lg-block  ml-30">
              <router-link to="/contact" class="header-btn">Visit Us</router-link>
            </div>
          </div>          
          <div class="col-12">
            <div class="mobile_menu d-block d-lg-none"></div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
  export default {
    name: 'HeaderComponent',
    props: {
      msg: String
    }
  }
</script>

<style scoped>
</style>
