<template>
  <main>
    <section class="testimonial-area testimonial-padding fix">
      <div class="container">
        <div class="row align-items-center justify-content-center">
          <div class=" col-lg-9">
            <div class="about-caption pb-bottom">
              <div class="h1-testimonial-active dot-style">
                <div class="single-testimonial position-relative">
                  <div class="testimonial-caption">
                    <img src="assets/img/icon/quotes-sign.png" alt="" class="quotes-sign">
                    <p>Brook presents your services with flexible, convenient and cdpose layouts. You can select your favorite layouts & elements for cular ts with unlimited ustomization possibilities. Pixel-perfect replica;ition of thei designers ijtls intended csents your se.</p>
                  </div>
                  <div class="testimonial-founder d-flex align-items-center">
                    <div class="founder-img">
                      <img src="assets/img/icon/testimonial.png" alt="">
                    </div>
                    <div class="founder-text">
                      <span>Robart Brown</span>
                      <p>Creative designer at Colorlib</p>
                    </div>
                  </div>
                </div>
                <div class="single-testimonial position-relative">
                  <div class="testimonial-caption">
                    <img src="assets/img/icon/quotes-sign.png" alt="" class="quotes-sign">
                    <p>Brook presents your services with flexible, convenient and cdpose layouts. You can select your favorite layouts & elements for cular ts with unlimited ustomization possibilities. Pixel-perfect replica;ition of thei designers ijtls intended csents your se.</p>
                  </div>
                  <div class="testimonial-founder d-flex align-items-center">
                    <div class="founder-img">
                      <img src="assets/img/icon/testimonial.png" alt="">
                    </div>
                    <div class="founder-text">
                      <span>Robart Brown</span>
                      <p>Creative designer at Colorlib</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="instagram-area fix pb-bottom">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-xl-6 col-lg-6 col-md-8 col-sm-10">
            <div class="section-tittle text-center mb-80">
              <h2>Follow us on Instagram</h2>
              <p>Explore our gallery of exquisite and classy wears on our instagram page</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 col-md-6">
            <div class="single-instagram mb-30">
              <img src="assets/img/gallery/instra1.png">
              <a href="https://www.instagram.com/3gfashion_/"><i class="ti-instagram"></i></a>
            </div>
          </div>
          <div class="col-lg-6 col-md-6">
            <div class="single-instagram">
              <img src="assets/img/gallery/instra2.png">
              <a href="https://www.instagram.com/3gfashion_/"><i class="ti-instagram"></i></a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
  <footer>
    <div class="footer-wrapper section-bg2 pl-100" style="background-image:url(assets/img/gallery/footer-bg.png);" data-background="assets/img/gallery/footer-bg.png">
      <div class="footer-area footer-padding">
        <div class="container">
          <div class="row justify-content-end">
            <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6">
              <div class="single-footer-caption mb-50">
                <div class="single-footer-caption mb-30">
                  <div class="footer-logo mb-35">
                    <router-link to="/"><img src="assets/img/logo/logo.png" style="max-height:80px;"></router-link>
                  </div>
                  <div class="footer-tittle">
                    <div class="footer-pera">
                      <p>
                        3gGlow Fashion - <br>
                        ...Glow is the goal
                      </p>
                    </div>
                    <ul class="mb-40">
                      <li class="number"><a href="mailto:+2348089980886">(+234)08089980886</a></li>
                      <li class="number2"><a href="mailto:+2348024245093">(+234)08024245093</a></li>
                    </ul>
                  </div>
                  <div class="footer-social">
                    <a href="https://www.instagram.com/3gfashion_/"><i class="fab fa-instagram"></i></a>
                    <a href="https://twitter.com/BillsDago"><i class="fab fa-twitter"></i></a>
                    <a href="https://fb.com"><i class="fab fa-facebook-f"></i></a>
                    <a href="https://pin.it/7qQ7tEe"><i class="fab fa-pinterest-p"></i></a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-2 col-lg-4 col-md-4 col-sm-6">
              <div class="single-footer-caption mb-50">
                <div class="footer-tittle">
                  <h4>Quick Links</h4>
                  <ul>
                    <li><router-link to="/">Home</router-link></li>
                    <li><router-link to="/about">About</router-link></li>
                    <li><router-link to="/services">Services</router-link></li>
                    <li><router-link to="/contact">Contact</router-link></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-bottom-area">
        <div class="container">
          <div class="footer-border">
            <div class="row align-items-center">
              <div class="col-xl-12 ">
                <div class="footer-copy-right text-right">
                  <p>
                    Copyright &copy; 2022 &nbsp;&nbsp; All rights reserved | A Product of <a href="https://3gglow.com">3gGlow Inc. <i class="fa fa-heart" aria-hidden="true"></i></a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="map-area">
        <img src="assets/img/gallery/map.png" alt="">
      </div>
    </div>
  </footer>
</template>

<script>
  export default {
    name: 'FooterComponent',
    props: {
      msg: String
    }
  }
</script>

<style scoped>
</style>
